<template>
    <div>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
            </template>
            <v-list dense>
                <v-subheader>{{ alle ? 'Med alle:' : 'Med valgte:' }}</v-subheader>
                <v-list-item-group color="primary">
                    <v-list-item v-on:click="copyEmail">
                        <v-list-item-icon>
                            <v-icon>mdi-at</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Kopier e-postadresser</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="exportExcelList">
                        <v-list-item-icon>
                            <v-icon>mdi-file-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Eksporter liste</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="exportExcelContactInfo">
                        <v-list-item-icon>
                            <v-icon>mdi-file-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Eksporter kontaktinformasjon</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-on:click="exportExcelConsentInfo">
                        <v-list-item-icon>
                            <v-icon>mdi-file-excel</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>Eksporter samtykkeinformasjon</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
        <v-dialog v-model="epostDialog" max-width="640">
            <v-card>
                <v-toolbar color="grey lighten-2">
                    <v-toolbar-title>E-postadresser</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="epostDialog = false">
                        <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <textarea ref="emails" rows="4" class="mt-4 pa-4" style="width: 100%" v-model="epostAdresser" v-on:focus="$event.target.select()" readonly>
                    </textarea>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" v-on:click="copyToClipboard">Kopier til utklippstavle</v-btn>
                    <v-btn v-on:click="epostDialog = false"> Lukk </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
    name: 'PersonsActions',
    props: {
        value: {
            default: function () {
                return [];
            },
            type: Array,
        },
        headers: {
            type: Array,
            default: null,
        },
        alle: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            epostAdresser: '',
            epostDialog: false,

            fields: {
                fulltNavn: {
                    label: 'Navn',
                    width: 40,
                },
                epost: {
                    label: 'E-postadresse',
                    width: 30,
                },
                mobil: {
                    label: 'Mobil',
                    width: 10,
                },
                adresseLinje1: {
                    label: 'Adresse',
                    width: 40,
                },
                postnr: {
                    label: 'Postnr.',
                    width: 10,
                },
                poststed: {
                    label: 'Poststed',
                    width: 10,
                },
                bydel: {
                    label: 'Bydel',
                    width: 25,
                },
                status: {
                    label: 'Status',
                    width: 15,
                },
                tjeneste: {
                    label: 'Tjeneste',
                    width: 20,
                },
                sorgGrupper: {
                    label: 'Sorggruppe',
                    width: 30,
                },
                sorgType: {
                    label: 'Gruppe',
                    width: 15,
                },
                forstegangssamtaleDato: {
                    label: 'Førstegangssamtale',
                    width: 20,
                },
                sendtSMSDato: {
                    label: 'Sendt SMS',
                    width: 20,
                },
                tilordnetSorgGruppeLopenr: {
                    label: 'Tilordnet SorgGruppe',
                    width: 20,
                },
                opprettet: {
                    label: 'Opprettet',
                    width: 20,
                },
                oppstartsdato: {
                    label: 'Oppstart',
                    width: 20,
                },
                lopenummer: {
                    label: 'Nr',
                    width: 15,
                },

                'samtykke.samtykket': {
                    label: 'Samtykket',
                    width: 10,
                },
                'samtykke.dato': {
                    label: 'Samtykke dato',
                    width: 20,
                },
                'samtykke.nyhetsbrev': {
                    label: 'Nyhetsbrev',
                    width: 20,
                },
                'samtykke.marked': {
                    label: 'Marked',
                    width: 20,
                },
                'samtykke.arrangement': {
                    label: 'Arrangement',
                    width: 20,
                },
                'samtykke.ingen': {
                    label: 'Ingen',
                    width: 20,
                },
                'samtykke.annen': {
                    label: 'Annen',
                    width: 20,
                },
                'samtykke.annenGrunn': {
                    label: 'Annen grunn',
                    width: 100,
                },
            },

            contactFields: ['fulltNavn', 'epost', 'mobil', 'adresseLinje1', 'postnr', 'poststed', 'bydel'],

            consentFields: [
                'fulltNavn',
                'epost',
                'mobil',
                'adresseLinje1',
                'postnr',
                'poststed',
                'bydel',
                'samtykke.samtykket',
                'samtykke.dato',
                'samtykke.nyhetsbrev',
                'samtykke.marked',
                'samtykke.arrangement',
                'samtykke.ingen',
                'samtykke.annen',
                'samtykke.annenGrunn',
            ],
        };
    },
    filters: {},
    methods: {
        ...mapActions(['snackbar']),
        ...mapActions('api', ['exportToExcel']),

        /**
         * copyEmail
         */
        copyEmail: function () {
            const emailAdresses = [];
            for (const person of this.value) {
                if (person.epost) {
                    emailAdresses.push(person.epost);
                }
            }

            this.epostAdresser = emailAdresses.join(';');
            this.epostDialog = true;

            window.setTimeout(() => {
                this.$refs.emails.focus();
            }, 200);
        },

        /**
         * copyToClipboard
         */
        copyToClipboard: function () {
            this.$refs.emails.focus();
            document.execCommand('copy');

            this.snackbar('Kopiert til utklippstavlen.');
            this.epostDialog = false;
        },

        /**
         * exportExcelList
         */
        exportExcelList: async function () {
            const table = [];
            const header = [];
            const wscols = [];

            const fields = [];

            for (let field of this.headers) {
                field = field.value;
                if (typeof this.fields[field] != 'undefined') {
                    header.push(this.fields[field].label);
                    wscols.push({ width: this.fields[field].width });

                    fields.push(field);
                } else {
                    console.log(field);
                }
            }

            table.push(header);
            table.push(...this.getExcelTableRowsFromFields(this.value, this.consentFields));

            await this.exportToExcel({
                filename: 'Personer',
                table: table,
                wscols: wscols,
            });
        },

        /**
         * exportExcelContactInfo
         */
        exportExcelContactInfo: async function () {
            const table = [];
            const header = [];
            const wscols = [];

            for (const field of this.contactFields) {
                header.push(this.fields[field].label);
                wscols.push({ width: this.fields[field].width });
            }

            table.push(header);
            table.push(...this.getExcelTableRowsFromFields(this.value, this.consentFields));

            await this.exportToExcel({
                filename: 'Kontaktinformasjon',
                table: table,
                wscols: wscols,
            });
        },

        /**
         * exportExcelConsentInfo
         */
        exportExcelConsentInfo: async function () {
            const table = [];
            const header = [];
            const wscols = [];

            for (const field of this.consentFields) {
                header.push(this.fields[field].label);
                wscols.push({ width: this.fields[field].width });
            }

            table.push(header);
            table.push(...this.getExcelTableRowsFromFields(this.value, this.consentFields));

            await this.exportToExcel({
                filename: 'Samtykketinformasjon',
                table: table,
                wscols: wscols,
            });
        },

        /**
         * getExcelTableFromFields
         */
        getExcelTableRowsFromFields: function (list, fields) {
            const rows = [];

            for (const person of list) {
                const row = [];
                for (const field of fields) {
                    switch (field) {
                        case 'status':
                            row.push(person.statusNavn);
                            break;

                        case 'tjeneste': {
                            const tjeneste = person.roller
                                ? person.roller
                                      .map((rolle) => {
                                          switch (rolle.id) {
                                              case 'FRIVILLIG_SORG':
                                              case 'BRUKER_SORG':
                                                  return 'Sorgtjenesten';

                                              case 'FRIVILLIG_HJEMME':
                                              case 'BRUKER_HJEMME':
                                                  return 'Hjemmetjeneste';
                                          }
                                          return null;
                                      })
                                      .filter((rolle) => rolle != null)
                                      .join(', ')
                                : [];
                            row.push(tjeneste);
                            break;
                        }

                        case 'sorgGrupper': {
                            const grupper = person.sorgGrupper ? person.sorgGrupper.map((gruppe) => gruppe.tittel).join(', ') : [];
                            row.push(grupper);
                            break;
                        }

                        case 'sorgType':
                            row.push(person.sorginformasjon && person.sorginformasjon.sorgType ? person.sorginformasjon.sorgType.navn : null);
                            break;

                        case 'forstegangssamtaleDato':
                            row.push(
                                person.sorginformasjon && person.sorginformasjon.forstegangssamtaleDato
                                    ? person.sorginformasjon.forstegangssamtaleDato.toDate()
                                    : ''
                            );
                            break;

                        case 'sendtSMSDato':
                            row.push(person.sorginformasjon && person.sorginformasjon.sendtSMSDato ? person.sorginformasjon.sendtSMSDato.toDate() : '');
                            break;

                        case 'tilordnetSorgGruppeLopenr':
                            row.push(
                                person.sorginformasjon && person.sorginformasjon.tilordnetSorgGruppeLopenr
                                    ? person.sorginformasjon.tilordnetSorgGruppeLopenr
                                    : ''
                            );
                            break;

                        default: {
                            let value = null;
                            if (field.indexOf('.') > 0) {
                                value = person;
                                for (const subfield of field.split('.')) {
                                    console.log('X:' + subfield);
                                    if (value && typeof value[subfield] != 'undefined') {
                                        value = value[subfield];
                                        console.log('Y:' + value);
                                    } else {
                                        value = null;
                                        break;
                                    }
                                }
                            } else {
                                value = person[field];
                            }

                            if (value && moment.isMoment(value)) {
                                value = value.toDate();
                            } else if (value && typeof value == 'boolean') {
                                value = value ? 'ja' : null;
                            }
                            row.push(value);
                        }
                    }
                }
                rows.push(row);
            }

            return rows;
        },
    },
};
</script>
